
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseIcon from '../../base/BaseIcon.vue'
import { ITeaserData } from '../../../shared/general/interfaces/ITeaserData'
import { ILink } from '../../../shared/general/interfaces/ILink'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import cutOff from '../../../shared/general/services/CutOffService'
import BaseHeadline from '../../base/BaseHeadline.vue'

@Component({
  name: 'TeaserPressReleaseOverview',
  components: {
    BaseGridLayout,
    BaseIcon,
    BaseHeadline,
    BaseLink: () => import('../../base/BaseLink.vue'),
    BaseTeaserHome: () => import('../../base/BaseTeaserHome.vue'),
  },
})
export default class TeaserPressReleaseOverview extends Vue {
  @Prop() headline ?: string

  @Prop() allPressReleasesLink ?: ILink

  @Prop({ default: '' }) noPressReleasesText! : string

  @Prop({ default: () => [] }) teasers! : ITeaserData[]

  private cut = cutOff

  private get gridCols () : string {
    return this.teasers.length === 1 ? 'lg:col-span-8 lg:col-start-3' : ''
  }
}
